import React, { useEffect, useRef, useState } from "react";
import styles from "../assets/scss/header.module.scss";

import logo from "../assets/img/hackagon-logo.svg";
import hamburgerIcon from "../assets/icon/hamburger.svg";

import menuRed from "../assets/img/menu-red.png";
import menuBlue from "../assets/img/menu-blue.png";
import menuCommunity from "../assets/img/menu-community.png";
import menuBox from "../assets/img/menu-box.png";

import useWindowSize from "../hooks/useWindowSize";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useResizeDetector } from "react-resize-detector";
gsap.registerPlugin(ScrollTrigger);

export default function Header() {
  const top = ".hackagon";
  const redTeam = ".redTeam";
  const blueTeam = ".blueTeam";
  const community = ".community";

  const mobileMenuRef = useRef(null);
  const menuBgRef = useRef(null);
  const navPinRef = useRef(null);
  const navigationRef = useRef(null);
  const homeWrapperRef = useRef(null);

  const menuBgTL = useRef(null);
  const menuItemsTL = useRef(null);
  const navPinTL = useRef(null);

  const size = useWindowSize();

  const [menuOpen, setMenuOpen] = useState(false);

  const [currentPin, setCurrentPin] = useState(null);

  const onResize = () => {
    ScrollTrigger.refresh();
  };

  useResizeDetector({ targetRef: homeWrapperRef, onResize });

  const handleNav = (section) => {
    const selectedSection = document.querySelector(section);
    selectedSection.scrollIntoView({ behavior: "smooth" });
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    homeWrapperRef.current = document.querySelector(".home-wrapper");

    if (mobileMenuRef.current) {
      mobileMenuRef.current.style.pointerEvents = "none";
    }

    const mobileMenuSelector = gsap.utils.selector(mobileMenuRef);
    const menuItems = mobileMenuSelector(".menu-item");

    if (menuBgRef.current && menuItems.length) {
      gsap.set([menuBgRef.current, menuItems], {
        autoAlpha: 0,
      });
    }

    gsap.to(navigationRef.current, {
      autoAlpha: 0,
      scrollTrigger: {
        trigger: "body",
        start: () =>
          `top+=${
            window.document.body.offsetHeight - window.innerHeight / 2
          } bottom`,
        end: "bottom bottom",
        onUpdate: () => {
          console.log(window.document.body.offsetHeight);
          console.log(window.innerHeight);
        },
        // markers: true,
        scrub: 0.1,
      },
    });

    menuBgTL.current = gsap
      .timeline({ paused: true })
      // .to(menuBgRef.current, {
      //   autoAlpha: 0,
      //   duration: 0,
      // })
      // .to(menuBgRef.current, {
      //   autoAlpha: 0.6,
      //   duration: 0.1,
      // })
      // .to(menuBgRef.current, {
      //   autoAlpha: 1,
      //   duration: 0.1,
      // })
      // .to(menuBgRef.current, {
      //   autoAlpha: 0.7,
      //   duration: 0.1,
      // })
      .to(menuBgRef.current, {
        autoAlpha: 1,
        duration: 0.4,
      });

    menuItemsTL.current = gsap
      .timeline({ paused: true })
      .to(menuItems, {
        autoAlpha: 0,
        duration: 0.2,
      })
      .to(menuItems, {
        autoAlpha: 0.1,
        duration: 0.4,
      })
      .to(menuItems, {
        autoAlpha: 0.2,
        duration: 0.2,
      })
      .to(menuItems, {
        autoAlpha: 1,
        duration: 0.1,
      })
      .to(menuItems, {
        autoAlpha: 0.5,
        duration: 0.1,
      })
      .to(menuItems, {
        autoAlpha: 1,
        duration: 0.3,
      });
  }, [size.width]);

  useEffect(() => {
    if (menuOpen) {
      if (mobileMenuRef.current) {
        mobileMenuRef.current.style.pointerEvents = "auto";
      }

      menuBgTL.current.play();
      menuItemsTL.current.play();
    } else {
      if (mobileMenuRef.current) {
        mobileMenuRef.current.style.pointerEvents = "none";
      }

      menuBgTL.current.reverse();
      menuItemsTL.current.reverse(0.1);
    }
  }, [menuOpen]);

  useEffect(() => {
    navPinTL.current = gsap
      .timeline({ paused: true })
      .to(navPinRef.current, {
        autoAlpha: 0,
        duration: 0,
      })
      .to(navPinRef.current, {
        autoAlpha: 1,
        duration: 0.1,
        delay: 0.3,
      })
      .to(navPinRef.current, {
        autoAlpha: 0.3,
        duration: 0.1,
      })
      .to(navPinRef.current, {
        autoAlpha: 1,
        duration: 0.1,
      });

    navPinTL.current.play();
  }, [currentPin]);

  return (
    <header className={styles.header}>
      <div className={styles.mobileBg}></div>
      <img
        src={logo}
        className={styles.logo}
        alt="Hackagon Logo"
        onClick={() => handleNav(top)}
      />
      {size.width > 1024 && (
        <button className={styles.kickstarterBtn}>
          <p>KICKSTARTER</p>
        </button>
      )}

      {size.width > 1024 ? (
        <div
          ref={navigationRef}
          className={styles.navigation}
          onMouseLeave={() => setCurrentPin(null)}
        >
          <div className={styles.navItem}>
            <p
              className={styles.navText}
              onClick={() => handleNav(top)}
              onMouseEnter={() => {
                setCurrentPin(menuBox);
              }}
            >
              HACKAGON
            </p>
          </div>
          <div className={styles.navItem}>
            <p
              className={styles.navText}
              onClick={() => handleNav(redTeam)}
              onMouseEnter={() => {
                setCurrentPin(menuRed);
              }}
            >
              RED TEAM
            </p>
          </div>
          <div className={styles.navItem}>
            <p
              className={styles.navText}
              onClick={() => handleNav(blueTeam)}
              onMouseEnter={() => {
                setCurrentPin(menuBlue);
              }}
            >
              BLUE TEAM
            </p>
          </div>
          <div className={styles.navItem}>
            <p
              className={styles.navText}
              onClick={() => handleNav(community)}
              onMouseEnter={() => {
                setCurrentPin(menuCommunity);
              }}
            >
              COMMUNITY
            </p>
          </div>
          {currentPin && (
            <img
              className={styles.navPin}
              src={currentPin}
              alt="Pinned Visual"
              ref={navPinRef}
            />
          )}
        </div>
      ) : (
        <>
          {
            <div className={styles.navigationMobile}>
              <div className={styles.hamburgerWrapper}>
                <img
                  className={styles.hamburger}
                  onClick={toggleMenu}
                  src={hamburgerIcon}
                  alt="Hamburger Icon"
                />
              </div>

              {
                <div className={styles.menuMobile} ref={mobileMenuRef}>
                  <div className={styles.menuBg} ref={menuBgRef}></div>
                  <div className={`${styles.menuItem} menu-item`}>
                    <p onClick={() => handleNav(top)}>HACKAGON</p>
                    <img src={menuBox} alt="" />
                  </div>
                  <div className={`${styles.menuItem} menu-item`}>
                    <p onClick={() => handleNav(redTeam)}>RED TEAM</p>
                    <img src={menuRed} alt="" />
                  </div>
                  <div className={`${styles.menuItem} menu-item`}>
                    <p onClick={() => handleNav(blueTeam)}>BLUE TEAM</p>
                    <img src={menuBlue} alt="" />
                  </div>
                  <div className={`${styles.menuItem} menu-item`}>
                    <p onClick={() => handleNav(community)}>COMMUNITY</p>
                    <img src={menuCommunity} alt="" />
                  </div>
                </div>
              }
            </div>
          }
        </>
      )}
    </header>
  );
}
