import React, { useEffect, useRef, useState } from "react";
import styles from "../assets/scss/home.module.scss";

import bannerImg from "../assets/img/banner-img.png";
import bannerLogo from "../assets/img/banner-logo.png";

import blackGradient from "../assets/img/gradient-black.svg";
import infoBox from "../assets/img/info-box.png";
import infoArena from "../assets/img/info-arena.png";
import infoCardRed from "../assets/img/card-red-hoodie.png";
import infoCardBlue from "../assets/img/card-blue-hoodie.png";
import cardRedSupply from "../assets/img/card-red-supply.png";
import cardRedInsider from "../assets/img/card-red-insider.png";
import redPlayer from "../assets/img/red-player.png";
import bluePlayer from "../assets/img/blue-player.png";
import redFtw from "../assets/img/red-ftw.png";
import blueStands from "../assets/img/blue-stands.png";
import blueCardFirewall from "../assets/img/blue-card-firewall.png";
import blueCardAntivirus from "../assets/img/blue-card-antivirus.png";
import teamBlueLightning from "../assets/img/teamBlueLightning.png";
import loadingGif from "../assets/img/loading.gif";
import mobileArrowDown from "../assets/img/mobile-banner-arrow.gif";

import octoGate from "../assets/img/octo-gate.png";
import octoArm1 from "../assets/img/octo-arm-1.png";
import octoArm2 from "../assets/img/octo-arm-2.png";
import octoArm3 from "../assets/img/octo-arm-3.png";
import octoArm4 from "../assets/img/octo-arm-4.png";

import bgVideo from "../assets/video/banner-bg-video.mp4";
import bgVideoMobile from "../assets/video/banner-bg-video-mobile.mp4";

import useWindowSize from "../hooks/useWindowSize";
import { useResizeDetector } from "react-resize-detector";

import gsap, { Expo } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default function Home() {
  const octoWrapperRef = useRef(null);
  const homeWrapperRef = useRef(null);
  const loadingRef = useRef(null);
  const bannerImgRef = useRef(null);
  const bannerLogoRef = useRef(null);
  const submitBtnRef = useRef(null);
  const submitBtnTL = useRef(null);

  const onResize = () => {
    ScrollTrigger.refresh();
  };

  useResizeDetector({ targetRef: homeWrapperRef, onResize });

  const size = useWindowSize();

  const [isSubmitVisible, setIsSubmitVisible] = useState(false);

  useEffect(() => {
    const octoSelector = gsap.utils.selector(octoWrapperRef);
    const homeSelector = gsap.utils.selector(homeWrapperRef);

    // Octopus Anims
    gsap.to(octoSelector(".octoGate"), {
      transformOrigin: "top center",
      yPercent: 3,
      repeat: -1,
      duration: 3,
      yoyo: true,
    });

    gsap.to(octoSelector(".arm1"), {
      transformOrigin: "top right",
      rotate: 5,
      opacity: 0.1,
      repeat: -1,
      duration: 2,
      yoyo: true,
    });

    gsap.to(octoSelector(".arm2"), {
      transformOrigin: "top right",
      rotate: 8,
      opacity: 0,
      repeat: -1,
      duration: 5,
      yoyo: true,
    });

    gsap.to(octoSelector(".arm3"), {
      transformOrigin: "top left",
      rotate: 8,
      repeat: -1,
      opacity: 0,

      duration: 4,
      yoyo: true,
    });

    gsap.to(octoSelector(".arm4"), {
      transformOrigin: "top left",
      rotate: 8,
      repeat: -1,
      opacity: 0,

      duration: 3,
      yoyo: true,
    });

    //Parallax Anims
    // const infoImgWrapper = homeSelector(".info-img-wrapper");
    const infoBox = homeSelector(".info-box");
    const infoArena = homeSelector(".info-arena");
    const redPlayer = homeSelector(".red-player");
    const bluePlayer = homeSelector(".blue-player");

    const infoCardBlue = homeSelector(".info-card-blue");
    const infoCardRed = homeSelector(".info-card-red");
    const rcSupply = homeSelector(".rc-supply");
    const rcInsider = homeSelector(".rc-insider");
    const bcAntivirus = homeSelector(".bc-antivirus");
    const bcFirewall = homeSelector(".bc-firewall");

    const parallax = (
      el,
      yPercent = 0,
      xPercent = 0,
      rotateY = 0,
      scale = 1,
      scrub = 1,
      markers = false
    ) => {
      if (!el) {
        console.log("Parallax element not found!");
        return;
      }

      gsap.to(el, {
        yPercent,
        xPercent,
        rotateY,
        scale,
        scrollTrigger: {
          trigger: el,
          scrub,
          markers,
          start: "top bottom-=30%",
        },
      });
    };

    // gsap.fromTo(
    //   [infoBox, infoArena],
    //   { yPercent: 30 },
    //   {
    //     yPercent: 0,
    //     stagger: 0.2,
    //     scrollTrigger: {
    //       trigger: infoImgWrapper,
    //       start: "top+=50% bottom",
    //       end: "bottom bottom",
    //       markers: true,
    //       toggleActions: "play none none none",
    //     },
    //   }
    // );

    parallax(infoBox, -15, 0, 0, 1, 2);
    parallax(infoArena, -20, 0, 0, 1, 1);

    parallax(redPlayer, -15, 0, 0, 1, 1);
    parallax(bluePlayer, -15, 0, 0, 1, 1);

    parallax(infoCardRed, -15, 0, 0, 1, 1);
    parallax(infoCardBlue, 20, 0, 0, 1, 1);

    parallax(rcSupply, -10, -5, 0, 1.1, 4);
    parallax(rcInsider, 10, 5, 0, 1, 4);

    parallax(bcFirewall, 10, 5, 20, 1.1, 2);
    parallax(bcAntivirus, -10, -10, 20, 1.1, 2);

    gsap.set(submitBtnRef.current, {
      autoAlpha: 0,
      duration: 0,
    });

    submitBtnTL.current = gsap
      .timeline({ paused: true })
      .to(submitBtnRef.current, {
        autoAlpha: 1,
        duration: 0.1,
      })
      .to(submitBtnRef.current, {
        autoAlpha: 0.1,
        duration: 0.1,
      })
      .to(submitBtnRef.current, {
        autoAlpha: 1,
        duration: 0.1,
      });

    return () => {
      ScrollTrigger.getAll().forEach((instance) => {
        instance.kill();
      });

      // This in case a scroll animation is active while the route is updated
      gsap.killTweensOf(window);
    };
  }, []);

  useEffect(() => {
    if (isSubmitVisible) {
      submitBtnTL.current.play();
    } else {
      submitBtnTL.current.reverse();
    }
  }, [isSubmitVisible]);

  // progress logic
  const up = () => {
    gsap.to(loadingRef.current, {
      autoAlpha: 0,
      ease: Expo.out,
      delay: 2,
      duration: 0.5,

      onComplete: () => {
        gsap.to(loadingRef.current, {
          autoAlpha: 0,
        });
      },
    });

    gsap.fromTo(
      bannerImgRef.current,
      {
        yPercent: 130,
      },
      { yPercent: 0, delay: 2.2, duration: 0.8 }
    );

    gsap.fromTo(
      bannerLogoRef.current,
      {
        yPercent: 130,
      },
      { yPercent: 0, delay: 2.3, duration: 0.8 }
    );
  };

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let img, c, tot;

    img = document.images;
    c = 0;
    tot = img.length;

    const imgLoaded = () => {
      c += 1;
      setProgress((((100 / tot) * c) << 0) + "%");

      if (c === tot) return up();
      if (c === tot) return;
    };

    const counter = () => {
      for (var i = 0; i < tot; i++) {
        var tImg = new Image();
        tImg.onload = imgLoaded;
        tImg.onerror = imgLoaded;
        tImg.src = img[i].src;
      }
    };

    counter();
  }, []);

  const [placeholderShown, setPlaceholderShown] = useState(true);

  return (
    <main className={`${styles.homeWrapper} home-wrapper`} ref={homeWrapperRef}>
      <div className={styles.loadingPanel} ref={loadingRef}>
        <img
          className={styles.loadingIndicator}
          src={loadingGif}
          alt="Loading Indicator"
        />
        <h1 className={styles.progress}>{progress}</h1>
      </div>
      <section className={`${styles.banner} hackagon`}>
        <video
          autoPlay={true}
          loop={true}
          playsInline={true}
          muted={true}
          className={styles.bgVideo}
        >
          <source
            src={size.width > 640 ? bgVideo : bgVideoMobile}
            type="video/mp4"
          />
        </video>
        <img
          src={blackGradient}
          alt="Black Gradient"
          className={styles.bannerGradient}
        />
        <div className={styles.bannerBlackGradient}></div>
        <img
          ref={bannerImgRef}
          className={styles.bannerImg}
          src={bannerImg}
          alt="Hackagon Player"
        />
        <img
          ref={bannerLogoRef}
          className={styles.bannerLogo}
          src={bannerLogo}
          alt="Hackagon Logo"
        />
      </section>
      <section className={styles.info}>
        {size.width <= 640 && (
          <img
            className={styles.downArrow}
            src={mobileArrowDown}
            alt="Down Arrow"
          />
        )}
        <h2 className={styles.textL}>
          Hackagon is a contemporary cyber security board game that displays
          up-to-date cyber attacks and defense methods. The game is played in
          teams with up to 2-4 players. Hackagon’s game theme revolves around
          the cyber challenge between Red Team and Blue Team.
        </h2>
        <div className={`${styles.imgWrapper} info-img-wrapper`}>
          <img
            className={`${styles.infoBox} info-box`}
            src={infoBox}
            alt="Board Game Box"
          />
          <img
            className={`${styles.infoArena} info-arena`}
            src={infoArena}
            alt="Board Game Arena"
          />
        </div>
        <h2 className={styles.textL} style={{ marginTop: "10vw" }}>
          The main purpose of the players is to come up with the best strategy
          to either find security gaps to attack or to strengthen the system by
          detecting vulnerabilities.
        </h2>
        <h2 className={styles.textL}>
          The sole and group decisions of using the game tools in a right manner
          determines the winner of the game.
        </h2>
        <div className={styles.imgWrapperAbs}>
          <img
            className={`${styles.infoCardBlue} info-card-blue`}
            src={infoCardBlue}
            alt="Board Game Arena"
          />
          <img
            className={`${styles.infoCardRed} info-card-red`}
            src={infoCardRed}
            alt="Board Game Box"
          />
        </div>
      </section>
      <section className={styles.teams}>
        <h1 className={styles.titleL}>Teams</h1>
        <div className={`${styles.teamRed} redTeam`}>
          <img
            className={`${styles.redPlayer} red-player`}
            src={redPlayer}
            alt="Red Player"
          />
          <div className={styles.right}>
            <img
              className={styles.redTeamFtw}
              src={redFtw}
              alt="Red Team FTW!"
            />
            <p className={styles.textM}>
              Red Team is the attacker of the game <br />
              which finds the vulnerabilities of the system <br /> hardware,
              software and social gaps.
            </p>
          </div>
        </div>
        <div className={styles.teamRedUtilities}>
          <div className={styles.left}>
            <h3 className={styles.titleM}>Utilities</h3>
            <p className={styles.textM}>
              Red Team aims to comprise the most damage in order to conquer the
              system. <br /> Red Team’s assets consist of; Ransomware Attacks,
              DDoS or BadUSB Attacks, Wi-Fi Attacks and similar malicious
              hardware and software vectors.
            </p>
          </div>
          <div className={styles.right}>
            <img
              className={`${styles.cardRedSupply} rc-supply`}
              src={cardRedSupply}
              alt="Game Card"
            />
            <img
              className={`${styles.cardRedInsider} rc-insider`}
              src={cardRedInsider}
              alt="Game Card"
            />
          </div>
        </div>
        <div className={styles.teamRedOctopus}>
          <div className={styles.octoWrapper} ref={octoWrapperRef}>
            <img
              className={`${styles.octoGate} octoGate`}
              src={octoGate}
              alt="Interdimensional Gate"
            />
            <img
              className={`${styles.octoArm1} ${styles.octoItem} arm1`}
              src={octoArm1}
              alt="Octopus Arm"
            />
            <img
              className={`${styles.octoArm2} ${styles.octoItem} arm2`}
              src={octoArm2}
              alt="Octopus Arm"
            />
            <img
              className={`${styles.octoArm3} ${styles.octoItem} arm3`}
              src={octoArm3}
              alt="Octopus Arm"
            />
            <img
              className={`${styles.octoArm4} ${styles.octoItem} arm4`}
              src={octoArm4}
              alt="Octopus Arm"
            />
          </div>
        </div>
        <div className={styles.dome}>
          {/* <img src={domeZ0} alt="" />
          <img src={domeZ1} alt="" />
          <img src={domeZ2} alt="" /> */}
        </div>
        <div className={styles.teamBlue}>
          <div className={styles.dome}></div>
          <div className={styles.dome}></div>
          <div className={styles.dome}></div>
          <div className={styles.left}>
            <img
              className={`${styles.blueTeamStands} blueTeam`}
              src={blueStands}
              alt="Blue team stands!"
            />
            <p className={styles.textM}>
              Blue Team is the defender of the game which detects the possible
              threats and ensures system security.
            </p>
          </div>
          <img
            className={`${styles.bluePlayer} blue-player`}
            src={bluePlayer}
            alt="Blue Player"
          />
        </div>
        <div className={styles.teamBlueUtilities}>
          <div className={styles.left}>
            <img
              className={`${styles.cardBlueAntivirus} bc-antivirus`}
              src={blueCardAntivirus}
              alt="Game Card"
            />
            <img
              className={`${styles.cardBlueFirewall} bc-firewall`}
              src={blueCardFirewall}
              alt="Game Card"
            />
          </div>
          <div className={styles.right}>
            <h3 className={styles.titleM}>Utilities</h3>
            <p className={styles.textM}>
              Blue Team periodically checks the system infrastructure in order
              to detect and defense against possible security breaches. <br />
              Blue Team’s consist of; RF Shields, Firewalls and such defense
              vectors that aim to protect the system via security softwares and
              protection techniques.
            </p>
          </div>
          <img
            className={styles.teamBlueLightning}
            src={teamBlueLightning}
            alt="Blue Lightning"
          />
        </div>
        <div className={`${styles.community} community`}>
          <h1 className={styles.titleXL}>Community</h1>
          <p className={styles.textXL}>
            Join the waitlist to be the first find out the release date of
            Hackagon game.
          </p>
          <div className={styles.inputWrapper}>
            <div
              className={styles.placeholderWrapper}
              onClick={() => {
                setPlaceholderShown(false);
              }}
            >
              <input type="text" />
              <p
                style={{ opacity: placeholderShown ? "1" : "0" }}
                className={styles.placeholder}
              >
                email address
              </p>
            </div>
            <div
              className={styles.emailAgreement}
              onClick={() => {
                setIsSubmitVisible((prev) => !prev);
              }}
            >
              <div className={styles.checkbox}>
                {isSubmitVisible && <div className={styles.checkboxPin}></div>}
              </div>
              <label className={styles.label}>
                By checking on the box you agree to receive emails from
                Hackagon.
              </label>
            </div>
            <button
              className={styles.submitBtn}
              ref={submitBtnRef}
              // style={{
              //   opacity: isSubmitVisible ? "1" : "0",
              //   visibility: isSubmitVisible ? "visible" : "hidden",
              // }}
            >
              {size.width > 1024 ? (
                <>
                  <div className={styles.overflowWrapper}>
                    <p className={styles.btnText}>sign up now</p>
                  </div>
                  <div className={styles.overflowWrapper}>
                    <p className={styles.btnText}>sign up now</p>
                  </div>
                  <div className={styles.overflowWrapper}>
                    <p className={styles.btnText}>sign up now</p>
                  </div>
                </>
              ) : (
                <p className={styles.btnText}>sign up now</p>
              )}
            </button>
          </div>
        </div>
      </section>
    </main>
  );
}
