import React from "react";
import styles from "../assets/scss/footer.module.scss";

import footerBg from "../assets/img/bg-footer-desktop.png";
import footerBgMobile from "../assets/img/bg-footer-mobile.png";

import footerLogo from "../assets/img/banner.png";

// import twitter from "../assets/icon/twitter.svg";
// import facebook from "../assets/icon/facebook.svg";
// import linkedin from "../assets/icon/linkedin.svg";
import instagram from "../assets/icon/instagram.svg";
import reddit from "../assets/icon/reddit.svg";
import bgg from "../assets/icon/bgg.svg";
import useWindowSize from "../hooks/useWindowSize";

export default function Footer() {
  const size = useWindowSize();

  return (
    <footer className={`${styles.footer} footer-wrapper`}>
      <img
        src={size.width > 640 ? footerBg : footerBgMobile}
        alt="Footer Background"
        className={styles.footerBg}
      />

      {size.width > 640 && (
        <>
          <img
            src={footerLogo}
            alt="Player with Hoodie"
            className={styles.footerLogo}
          />
          <div className={styles.actions}>
            <h2>
              We’re live on <br /> Kickstarter now!
            </h2>
            <button>
              <p>JOIN US!</p>
            </button>
          </div>
          <div className={styles.backdrop}></div>
          <div className={styles.bottom}>
            <div className={styles.copyright}>
              <p>© All rights reserved. 2022 Hackagon.</p>
              <p style={{ paddingLeft: "1rem" }}>
                Made by{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://justdesignfx.com/"
                >
                  JUST DESIGN FX
                </a>
              </p>
            </div>
            <div className={styles.links}>
              <img src={instagram} alt="Instagram" />
              {/* <img src={linkedin} alt="Linkedin" />
              <img src={twitter} alt="Twitter" />
              <img src={facebook} alt="Facebook" /> */}
              <img src={reddit} alt="Reddit" />
              <img src={bgg} alt="Bgg" />
            </div>
          </div>
        </>
      )}

      {size.width <= 640 && (
        <div className={styles.footerMobileBottom}>
          <div className={styles.actions}>
            <h2>
              We’re live on <br /> Kickstarter now!
            </h2>
            <button>
              <p>JOIN US!</p>
            </button>
          </div>
          <div className={styles.backdrop}></div>
          <div className={styles.bottom}>
            <div className={styles.copyright}>
              <p>© All rights reserved. 2022 Hackagon.</p>
              <p>
                Made by{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://justdesignfx.com/"
                >
                  JUST DESIGN FX
                </a>
              </p>
            </div>
            <div className={styles.links}>
              <img src={instagram} alt="Instagram" />
              {/* <img src={linkedin} alt="Linkedin" />
              <img src={twitter} alt="Twitter" />
              <img src={facebook} alt="Facebook" /> */}
              <img src={reddit} alt="Reddit" />
              <img src={bgg} alt="Bgg" />
            </div>
          </div>
        </div>
      )}
    </footer>
  );
}
