import Home from "./pages/home";
import smoothscroll from "smoothscroll-polyfill";

import Header from "./components/header";
import Footer from "./components/footer";
import { useEffect } from "react";
import gsap from "gsap";

function App() {
  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <>
      <Header />
      <Home />
      <Footer />
    </>
  );
}

export default App;
